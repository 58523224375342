<template>
  <div class="container">
    <nav-bar :title="menuName"></nav-bar>
    <md-field>
      <md-cell-item
        v-for="item in questions"
        :key="item.id"
        :title="`${item.number}.${item.name}`"
        arrow
        @click="handlePopupShow(item)"
        addon="详情"
      >
        <div slot="children" @click="e => e.stopPropagation()" v-if="!isCase">
          <md-radio
            :name="true"
            v-model="item.answer"
            label="存在此问题"
            inline
            size="lg"
            :icon-svg="true"
            icon="checkRed"
            icon-inverse="uncheck"
          />
          <md-radio
            :name="false"
            v-model="item.answer"
            label="没有此问题"
            inline
            size="lg"
            :icon-svg="true"
            icon="checkGreen"
            icon-inverse="uncheck"
          />
          <v-img-upload v-model="item.pic" :show-tip="false" size="sm" :limit="3"></v-img-upload>
          <md-input-item class="padding" v-model="item.remark" placeholder="请输入备注" clearable></md-input-item>
        </div>
      </md-cell-item>
    </md-field>
    <md-popup v-model="popupShow" position="bottom">
      <md-popup-title-bar only-close large-radius title="问题解析" title-align="left" @cancel="popupShow = false"></md-popup-title-bar>
      <div class="popup">
        <md-scroll-view :scrolling-x="false" auto-reflow>
          <md-tabs v-model="activeTab" v-if="popupShow">
            <md-tab-pane name="harm" label="造成危害">
              <div v-html="harm" class="rich-text padding-lg"></div>
            </md-tab-pane>
            <md-tab-pane name="reason" label="原因简析">
              <div v-html="reason" class="rich-text padding-lg"></div>
            </md-tab-pane>
            <md-tab-pane name="law" :label="lawTitle">
              <div v-html="law" class="rich-text padding-lg"></div>
            </md-tab-pane>
          </md-tabs>
          <md-swiper ref="swiper" class="padding" v-if="images.length > 0" :autoplay="0" :is-prevent="false" @after-change="afterChange">
            <md-swiper-item :key="index" v-for="(item, index) in images">
              <img :src="item.url" style="width:100%;" @click="handleSwiperClick(index)" />
            </md-swiper-item>
          </md-swiper>
          <div class="center padding-lr font-lg">{{ imageDesc[swiperIndex || 0] }}</div>
        </md-scroll-view>
      </div>
    </md-popup>
    <md-action-bar :actions="[{ text: '生成报告', onClick: this.handleSubmit }]"></md-action-bar>
    <md-progress
      :value="progressValue"
      :width="10"
      :size="70"
      color="url(#linear)"
      border-color="#FFF"
      transition
      class="progress"
      v-if="progressValue > 0"
      style="z-index:2"
    >
      <span class="font-sm">{{ `${(progressValue * 100).toFixed(1)}%` }}</span>
      <defs slot="defs">
        <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stop-color="#722ed1" />
          <stop offset="100%" stop-color="#2f86f6" />
        </linearGradient>
      </defs>
    </md-progress>
    <md-dialog :closable="false" v-model="imageDescDialog.open" :btns="imageDescDialog.btns">
      <div slot="header">
        <img :src="images[swiperIndex] && images[swiperIndex].url" style="width:100%;" @click="$refs.viewer.$viewer.view(swiperIndex)" />
      </div>
      {{ imageDesc[swiperIndex] }}
    </md-dialog>
    <viewer :images="images" ref="viewer">
      <img v-for="(item, index) in images" :src="item.url" :key="index" v-show="false" />
    </viewer>
  </div>
</template>

<script>
import _ from 'lodash'
import * as questionApi from '@/api/question'
import VImgUpload from '@/components/VImgUpload/Index'
import '@/assets/svg/checkRed.svg'
import '@/assets/svg/checkGreen.svg'
import '@/assets/svg/uncheck.svg'

export default {
  components: { VImgUpload },
  data() {
    return {
      questions: [],
      popupShow: false,
      desc: '',
      harm: '',
      reason: '',
      law: '',
      images: [],
      imageDesc: [],
      menuId: this.$route.params.menuId,
      menuName: this.$route.params.menuName,
      imageDescDialog: {
        open: false,
        btns: [
          {
            text: '关闭',
            handler: () => (this.imageDescDialog.open = false),
          },
        ],
      },
      swiperIndex: 0,
      isCase: false,
      activeTab: '',
    }
  },
  computed: {
    answers() {
      return this.questions.map(item => {
        const { id, name, number, answer, pic, remark } = item
        return { id, name, number, answer, pic, remark }
      })
    },
    unAnswerNum() {
      let unAnswer = 0
      this.answers.forEach(item => {
        if (item.answer === '') {
          unAnswer += 1
        }
      })
      return unAnswer
    },
    progressValue() {
      return (this.questions.length - this.unAnswerNum) / this.questions.length || 0
    },
    lawTitle() {
      return this.isCase ? '处理结果' : '法规要求'
    },
  },
  created() {
    questionApi.list({ menu_id: this.menuId }).then(res => {
      this.questions = res.data.map(item => {
        const answers = JSON.parse(this.$ls.get(`answers_${this.menuId}`)) || []
        const index = _.findIndex(answers, _item => _item.id === item.id)
        if (answers && index > -1) {
          item.answer = answers[index].answer
          item.pic = answers[index].pic
          item.remark = answers[index].remark
        } else {
          item.answer = ''
          item.pic = []
          item.remark = ''
        }
        return item
      })
      this.isCase = this.questions.length > 0 && this.questions[0].type === 1
    })
  },
  methods: {
    handleClick(val) {
      if (val.has_children) {
        this.$router.push({ name: 'sub-menu', params: { parentId: val.id, parentName: val.name } })
      }
    },
    handlePopupShow(val) {
      this.isShow = true
      this.activeTab = 'harm'
      this.images = []
      this.imageDesc = []
      this.$nextTick(() => {
        this.popupShow = true
        const data = JSON.parse(JSON.stringify(val))
        this.desc = data.desc
        this.harm = data.harm
        this.reason = data.reason
        this.law = data.law
        this.images = data.images
          ? data.images.map(item => {
              item.url = process.env.VUE_APP_BASE_BACK_URL + item.url
              return item
            })
          : []
        this.imageDesc = data.image_desc ? data.image_desc.split('#') : []
      })
    },
    handleSubmit() {
      if (this.unAnswerNum === this.questions.length) {
        this.$dialog.alert({
          title: '警告',
          icon: 'info',
          content: '还没有勾选任何选项，不能提交',
        })
      } else if (this.unAnswerNum > 0) {
        this.$dialog.confirm({
          title: '确认',
          content: `还有${this.unAnswerNum}个选项未做选择，是否继续生成报告？`,
          confirmText: '确定',
          onConfirm: () => this.$router.push({ name: 'result', params: { menuId: this.menuId, menuName: this.menuName } }),
        })
      } else {
        this.$router.push({ name: 'result', params: { menuId: this.menuId, menuName: this.menuName } })
      }
    },
    handleSwiperClick(index) {
      this.swiperIndex = index
      this.imageDescDialog.open = true
    },
    afterChange(from, to) {
      this.swiperIndex = to
    },
  },
  watch: {
    answers(newVal) {
      this.$ls.set(`answers_${this.menuId}`, JSON.stringify(newVal))
    },
  },
}
</script>
<style lang="stylus" scoped>
.container
  padding-bottom 100px
.popup
  background-color #FFF
  .md-tabs >>> .md-tab-bar
    background-color #FFF
  .md-scroll-view
    height 600px
.progress
  position fixed
  bottom 2rem
  right 0.5rem
>>>.md-radio-label
  font-size 32px
>>>.md-dialog-body
  padding 20px
.slider
  z-index 9999 !important
</style>
